import mock from '../mock'
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'CreditCard',
      title: 'Регистрация',
      subtitle: 'Начало работы с системой',
      qandA: [
        {
          question: 'Как зарегистрировать новый аккаунт и какие данные нужны?',
          ans: 'Зарегистрировать новый аккаунт в Worksection очень просто. Для этого перейдите на страницу «Тарифные планы и цены» , введите ваш email и нажмите на «Готово». Далее, на указанный вами адрес придет письмо со ссылкой на продолжение регистрации. Перейдите по ней. Перейдя по ссылке, вы попадете на страницу ввода регистрационной информации.'
        },
        {
          question: 'Как пригласить человека в аккаунт?',
          ans: 'Чтобы пригласить коллегу в аккаунт, нажмите на «зеленый плюсик» и на пункт «Пригласить людей». В открывшемся окне укажите проект, в который хотите добавить участника. Затем впишите его email, имя и фамилию. На указанный email участник получит приглашение в аккаунт. Вы можете персонифицировать ваше приглашение, добавив к нему личное сообщение. После заполнения — нажмите на «Пригласить».'
        },
        {
          question: 'Сколько стоят услуги? Есть ли ограничения по количеству пользователей?',
          ans: 'Стоимость использования Worksection варьируется от полностью бесплатного тарифа до $199 в месяц. Детальная информация о тарифных планах и функциональных возможностях каждого находится на странице Тарифные планы и цены. Функциональность тарифа Мини достаточна для личного использования. Базовый подходит для небольших компаний, а Бизнес и Премиум — для крупных компаний с большим числом проектов и сотрудников.'
        },
        {
          question: 'Зарегистрирован в нескольких аккаунтах. Как быстро переключаться?',
          ans: 'Нажмите на вашу аватарку в верхнем правом углу страницы. Нажмите на стрелочку возле вашего имени, чтобы отобразить список всех аккаунтов, в которых есть учетные записи с вашим email. Текущий аккаунт отмечен значком . Выберете строку с аккаунтом, в который хотите перейти, и кликните по нему.'
        },
        {
          question: 'Есть ли в Worksection пробный период?',
          ans: 'Да! 14 дней после регистрации вы можете пользоваться системой совершенно бесплатно. В течение тестового периода вам будут доступны все возможности системы, за исключением функций из тарифов «PRO» уровня (собственного домена, SSL-шифрования и резервного копирования данных).'
        },
        {
          question: 'Можно ли работать в Worksection бесплатно?',
          ans: 'Да, можно! По окончанию пробного периода, вы можете перейти на бесплатный тариф и работать в нём столько, сколько потребуется. В бесплатном тарифе доступно 100 Mb дискового пространства и 2 активных проекта. Количество пользователей — 5, включая владельца аккаунта. Как только ваши потребности превысят возможности бесплатного тарифа, вы легко можете перейти на любой другой тарифный план, в соответствии с вашими потребностями.'
        },
        {
          question: 'Адрес, который я хочу зарегистрировать, занят. Что делать?',
          ans: 'При регистрации в системе Worksection, вам необходимо придумать «имя» вашего аккаунта, выбрать дата-центр и домен. Если выбранное вами «имя» занято, вы увидите предупреждение, которое не позволит завершить регистрацию. Не расстраивайтесь... Попробуйте придумать другое «имя» или выбрать другой домен, на котором это же «имя» будет свободно.'
        }
      ]
    },
    // delivery
    delivery: {
      icon: 'ShoppingBag',
      title: 'Безопасность',
      subtitle: 'Насколько безопасно наше приложение',
      qandA: [
        {
          question: 'Какие гарантии безопасности вы предоставляете?',
          ans: 'Мы защищаем конфиденциальность наших клиентов и не передаем персональные данные третьим лицам. Ни один сотрудник не имеет доступа к серверам, поэтому за время существования компании не было случаев утечки данных. Основатели компании прежде всего заинтересованы в защите конфиденциальности клиентов. SSL шифрование реализовано на всех тарифах ради безопасной передачи данных между клиентом и сервером.'
        },
        {
          question: 'Как передать владение аккаунтом?',
          ans: 'На главной странице аккаунта выберите Аккаунт -> Безопасность. В блоке Владелец 2 из выпадающего списка выберите нового владельца аккаунта. Примечание! Передать владение аккаунтом может только владелец аккаунта и только «Администратору аккаунта».'
        },
        {
          question: 'Как перейти к настройке безопасности аккаунта?',
          ans: 'Нажмите на меню «три точки» возле названия аккаунта и выберите раздел Безопасность. Или же, перейдите в раздел Аккаунт -> Безопасность, нажав на аватар в правом верхнем углу. Примечание: перейти в раздел Аккаунт и во все его блоки может только владелец аккаунта и администратор аккаунта с правами «Доступ к настройке и оплате аккаунта».'
        },
        {
          question: 'Где физически хранятся мои данные?',
          ans: 'Worksection имеет несколько серверных площадок для хранения данных. Данные хранятся в датацентрах крупнейших хостинг-провайдеров Украины: MiroHost и ColoCall в Киеве, а также в датацентре Hetzner (Германия, Нюрнберг). Кластер серверов в свою очередь обеспечивает отказоустойчивость, непрерывную доступность и дополнительную безопасность. В случае проблем с одним из дата-центров или выхода из строя одного из серверов, его функции автоматически примет на себя другой сервер в другом дата-центре.'
        },
        {
          question: 'Могу ли я делать бекап своего аккаунта?',
          ans: 'Вы можете настроить резервное копирование всего аккаунта на ваш собственный FTP сервер. Резервное копирование доступно для аккаунтов с тарифным планом Премиум и выше. Включить и настроить его можно в разделе Аккаунт > Безопасность.'
        }
      ]
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'RefreshCw',
      title: 'Миграция',
      subtitle: 'Управление данными',
      qandA: [
        {
          question: 'Возможно ли мигрировать в Worksection из других систем управления проектами?',
          ans: 'Да! В автоматическом режиме вы можете мигрировать в Worksection. Также мы можем помочь вам импортировать ваши данные в ручном или полуавтоматическом режиме из других систем. Для этого напишите нам в службу поддержки.'
        },
        {
          question: 'Как попасть на страницу Миграция?',
          ans: 'Для этого перейдите в раздел Аккаунт -> Безопасность. В блоке Копия данных аккаунта нажмите «Скачать». Будет загружен XML файл, упакованный в одноименный ZIP архив.'
        },
        {
          question: 'Как экспортировать в .xml файл данные для миграции из Worksection?',
          ans: 'Для этого перейдите в раздел Аккаунт -> Безопасность. В блоке Копия данных аккаунта нажмите «Скачать». Будет загружен XML файл, упакованный в одноименный ZIP архив.'
        },
        {
          question: 'Как мигрировать с Basecamp 3 в Worksection?',
          ans: 'Чтобы импортировать данные с Basecamp 3 в сервис — необходимо прислать нам из него архив данных. Затем, мы импортируем эти данные в ваш аккаунт. Чтобы скачать данные, зайдите в ваш аккаунт в Basecamp 3 и на странице Home нажмите на Adminland.'
        },
        {
          question: 'Как мигрировать с Asana в Worksection?',
          ans: 'Чтобы импортировать данные из Asana в сервис, необходимо получить временный ключ. Чтобы его получить, перейдите по ссылке: /account_api. В открывшемся окне нажмите «Manage Developer Apps».Чтобы импортировать данные из Asana в сервис, необходимо получить временный ключ. Чтобы его получить, перейдите по ссылке: /account_api. В открывшемся окне нажмите «Manage Developer Apps».'
        },
        {
          question: 'Как настроить экспорт?',
          ans: 'Выберите какие проекты (или все, нажав на «Select all unarchived») хотите скачать и нажмите на Export my data. Затем будет сформирован архив для скачивания. Для скачивания архива нажмите «Download my export». Скаченный архив пришлите нам. '
        },
        {
          question: 'Как перейти из Jira',
          ans: 'Чтобы импортировать данные из Jira в сервис, необходимо получить временный ключ. Чтобы его получить, перейдите по ссылке: /account_api. В открывшемся окне нажмите «Manage Developer Apps».Чтобы импортировать данные из Asana в сервис, необходимо получить временный ключ. Чтобы его получить, перейдите по ссылке: /account_api. В открывшемся окне нажмите «Manage Developer Apps».'
        }
      ]
    },
    // my orders
    myOrders: {
      icon: 'Package',
      title: 'Настройки',
      subtitle: 'Настройки и управление',
      qandA: [
        {
          question: 'Где находятся общие настройки аккаунта?',
          ans: 'Перейдите в раздел Люди. Кликните в название компании. В раскрывшемся меню в блоке Логотип компании нажмите кнопку «Обзор...»1 и выберите файл с логотипом. Затем нажмите кнопку «Сохранить» и логотип будет загружен. Для удаления логотипа нажмите на значок.'
        },
        {
          question: 'Как сменить имя или домен своего аккаунта?',
          ans: 'Изменение поддоменного имени аккаунта. Перейдите в раздел Аккаунт -> Настройки системы. Введите новое поддоменное имя 1 и при необходимости выберите в раскрывающемся списке новую доменную зону (кроме .сom также доступны .org, .net, .ua и .ru) 2. Если выбранный вами адрес окажется свободным, вы увидите зеленую галочку 3, после чего можно подтвердить свое решение нажатием кнопки «Сохранить».'
        },
        {
          question: 'Как выбрать язык интерфейса аккаунта?',
          ans: 'Перейдите в раздел Аккаунт -> Настройки системы 1. Затем, в блоке Язык интерфейса выберите один из вариантов English, Русский или Українська2 и нажмите кнопку «Сохранить». Обратите внимание! Установка языка интерфейса аккаунта влияет только на установку языка по умолчанию. Каждый участник системы может сам установить удобный ему язык. Сделать это можно как на странице входа, так и в настройках персонального профиля.'
        },
        {
          question: 'Как установить часовой пояс аккаунта?',
          ans: 'Перейдите в раздел Аккаунт на страницу Настройки системы 1. В блоке «Основной часовой пояс», в раскрывающемся списке, выберите нужный город2 и нажмите кнопку «Сохранить». Если вашего города (часового пояса) нет в кратком списке, пролистайте список вниз и нажмите на самую нижнюю строку «...все временные зоны...».'
        },
        {
          question: 'Как установить валюту аккаунта?',
          ans: 'Перейдите в раздел Аккаунт -> Настройки системы 1. В блоке Учёт финансов в поле Валюта аккаунта 2 введите наименование или значок валюты и нажмите кнопку «Сохранить». Здесь же, отметив соответствующие чекбосы, можно разрешить сотрудникам самостоятельно вносить финансовые затраты по выполненным задачам, а также установить почасовую ставку для сотрудников по умолчанию.'
        }
      ]
    },
    // product and services
    productServices: {
      icon: 'Settings',
      title: 'Оплата аккаунта',
      subtitle: 'Настройка платежей',
      qandA: [
        {
          question: 'Есть ли ограничения по количеству пользователей?',
          ans: 'Количество пользователей в системе ограничено. Сколько людей может быть в аккаунте — зависит от вашего тарифного плана. Изменить тарифный план может только Владелец аккаунта и Администратор аккаунта.'
        },
        {
          question: 'До какого числа нужно оплатить аккаунт?',
          ans: 'Технология 3D Secure является частью глобальных программ Visa «Verified by Visa» и MasterCard «MasterCardSecureCode», целью которых является предоставление и обеспечение безопасных и надежных методов оплаты товаров и услуг в глобальной сети Интернет.'
        },
        {
          question: 'Можно ли заплатить сразу за несколько месяцев?',
          ans: 'Конечно! Вы можете оплатить аккаунт сразу на несколько месяцев. Кроме этого, вы можете внести на баланс вашего Worksection аккаунта произвольную сумму, которая ежемесячно будет списываться в счет оплаты аккаунта.'
        },
        {
          question: 'Как обеспечивается безопасность платежей?',
          ans: 'Наш сервис подключён к системе безопасных электронных платежей ПриватБанка, которая позволяет оплачивать наши услуги по любым картам Visa и MasterCard, выпущенных любыми зарубежными банками. Система безопасных электронных платежей реализована на самом современном стандарте безопасности — 3D Secure, который обеспечит максимальную безопасность ваших платежей в Интернете.'
        }
      ]
    }
  }
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    filteredData[categoryName] = {
      ...categoryObj,
      qandA: filteredQAndAOfCategory.length ? filteredQAndAOfCategory : []
    }
  })

  return [200, filteredData]
})
