import mock from '../mock'

const data = {
  // blog list
  blogList: [
    {
      img: require('@src/assets/images/slider/02.jpg').default,
      title: 'Качества, которые свойственны продавцам мирового класса',
      id: 1,
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      blogPosted: 'Янв 10, 2020',
      tags: ['Quote', 'Fashion'],
      excerpt: 'Эти качества отличают их от других: взаимодействие, ответственность и перспектива.',
      comment: 76
    },
    {
      img: require('@src/assets/images/slider/06.jpg').default,
      title: 'ЗАЧЕМ ПРОВОДИТЬ WIN / LOSS АНАЛИЗ?',
      id: 2,
      avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      userFullName: 'Jorge Griffin',
      blogPosted: 'Янв 10, 2020',
      tags: ['Gaming', 'Video'],
      excerpt: 'Организации мирового класса задаются вопросом и исследуют его: «Почему мы действительно выиграли сделку?',
      comment: 2100
    },
    {
      img: require('@src/assets/images/slider/04.jpg').default,
      title: 'ВЫЗОВЫ ПРОДАЖ В ДИНАМИЧЕСКОМ МИРЕ – ПОТРЕБНОСТИ И ПЕРСПЕКТИВА',
      id: 3,
      avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      userFullName: 'Claudia Neal',
      blogPosted: 'Янв 10, 2020',
      tags: ['Gaming', 'Food'],
      excerpt: 'Для достижения желаемых результатов, лучшие продавцы предоставляют решения по потребностям.',
      comment: 243
    },
    {
      img: require('@src/assets/images/slider/03.jpg').default,
      title: 'ПЯТЬ СТРАТЕГИЙ ВЫИГРЫША В 2022 ГОДУ',
      id: 4,
      avatar: require('@src/assets/images/portrait/small/avatar-s-14.jpg').default,
      userFullName: 'Fred Boone',
      blogPosted: 'Янв 10, 2020',
      tags: ['Video'],
      excerpt: 'Выигрыш - это намного больше удовольствия, чем игра догнать лидера рынка.',
      comment: 10
    },
    {
      img: require('@src/assets/images/slider/09.jpg').default,
      title: 'ОЦЕНКА ЭФФЕКТИВНОСТИ ПРОДАВЦОВ – ЭТО БОЛЬШЕ, ЧЕМ ПРОСТО ЦИФРЫ',
      id: 5,
      avatar: require('@src/assets/images/portrait/small/avatar-s-13.jpg').default,
      userFullName: 'Billy French',
      blogPosted: 'Янв 10, 2020',
      tags: ['Quote', 'Fashion'],
      excerpt: 'Исторически сложилось так, что существует всего один способ оценки эффективности продавца – выполнил или не выполнил цель.',
      comment: 319
    },
    {
      img: require('@src/assets/images/slider/10.jpg').default,
      title: 'ИНДИВИДУАЛЬНЫЙ ПЛАН ПОВЫШЕНИЯ ЭФФЕКТИВНОСТИ',
      id: 6,
      avatar: require('@src/assets/images/portrait/small/avatar-s-13.jpg').default,
      userFullName: 'Helena Hunt',
      blogPosted: 'Янв 10, 2020',
      tags: ['Fashion', 'Video'],
      excerpt: 'Наша организация продаж постоянно разрабатывает и обеспечивает внедрение индивидуальных планов повышения эффективности.',
      comment: 1500
    }
  ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        img: require('@src/assets/images/banner/banner-22.jpg').default,
        title: 'ЗАЧЕМ НУЖЕН ОБМЕН ПЕРЕДОВЫМИ ПРАКТИКАМИ ПРОДАЖ?',
        id: 7,
        createdTime: 'Янв 14 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-27.jpg').default,
        title: 'РУКОВОДИТЕЛИ ПО ПРОДАЖАМ – ЭТО СТЕРЖЕНЬ ВАШЕЙ ОРГАНИЗАЦИИ ПРОДАЖ',
        id: 8,
        createdTime: 'Мар 04 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-39.jpg').default,
        title: 'ПОЧЕМУ МЫ ТЕРЯЕМ ПРОДАВЦОВ?',
        id: 9,
        createdTime: 'Фев 18 2020'
      },
      {
        img: require('@src/assets/images/banner/banner-35.jpg').default,
        title: 'ОБЕЩАНИЕ БРЕНДА И КЛИЕНТСКИЙ ОПЫТ',
        id: 10,
        createdTime: 'Окт 08 2020'
      }
    ],
    categories: [
      { category: 'Продажи', icon: 'Watch' },
      { category: 'Рынки', icon: 'ShoppingCart' },
      { category: 'Промышленность', icon: 'Command' },
      { category: 'Бюджет', icon: 'Hash' },
      { category: 'Видео', icon: 'Video' }
    ]
  },

  // detail
  blogDetail: {
    blog: {
      img: require('@src/assets/images/banner/banner-12.jpg').default,
      title: 'Качества, которые свойственны продавцам мирового класса',
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      userFullName: 'Ghani Pradita',
      createdTime: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      content:
        '<p class="card-text mb-2">Сегодня продавцы находятся под постоянным давлением. Для достижения успеха, кроме постоянного развития навыков продавца им необходимо выполнение ряд стратегических задач фокусированных на процессе. Но даже эти навыки – недостаточны, если продавцу не удается управлять взаимоотношениями с клиентом, которые приведут к win-win результату. Клиент не слышит продавца, который не пытается понять их задачи и вызовы. Клиентов не устраивает продажа продукта – они покупают решение, соответствующие их потребностям. Клиент не полагается исключительно на информацию, которую предоставляет продавец  о продуктах или услугах.</p><h4>Вызовы, связанные с конкуренцией</h4><ul><li>Отсутствие дифференциации</li><li>Более качественные решения конкурентов</li><li>Закрепившиеся конкуренты</li><li>Низкие цены конкурентов</li><li>Длинные циклы покупок клиентов</li><li>Ценовое давление</li></ul>',
      comments: 19100,
      bookmarked: 139
    },
    comments: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        userFullName: 'Chad Alexander',
        commentedAt: 'May 24, 2020',
        commentText:
          'A variation on the question technique above, the multiple-choice question great way to engage your reader.'
      }
    ]
  },

  // edit
  blogEdit: {
    avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
    userFullName: 'Chad Alexander',
    createdTime: 'May 24, 2020',
    blogTitle: 'The Best Features Coming to iOS and Web design',
    blogCategories: [
      { value: 'fashion', label: 'Fashion' },
      { value: 'gaming', label: 'Gaming' }
    ],
    slug: 'the-best-features-coming-to-ios-and-web-design',
    status: 'Published',
    excerpt:
      '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
    featuredImage: require('@src/assets/images/slider/03.jpg').default
  }
}

mock.onGet('/blog/list/data').reply(() => [200, data.blogList])
mock.onGet('/blog/list/data/sidebar').reply(() => [200, data.blogSidebar])
mock.onGet('/blog/list/data/detail').reply(() => [200, data.blogDetail])
mock.onGet('/blog/list/data/edit').reply(() => [200, data.blogEdit])
