import mock from '../mock'
/*eslint-disable */
const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)

const data = {
  profileUser: {
    id: 11,
    avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
    fullName: 'Федор Двинятин',
    role: 'продавец',
    about:
      'Занимаюсь продажами уже 12 лет, повышаю показатели по оборачиваемости в несколько раз.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  },
  contacts: [
    {
      id: 1,
      fullName: 'София Никонова',
      role: 'ВЭД сопровождение',
      about: 'В международной логистике прошла путь от экспедитора до Директора по ВЭД',

      avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      status: 'offline'
    },
    {
      id: 2,
      fullName: 'Сергей Новиков',
      role: 'оператор',
      about:
        'Сервисное сопровождение клиентов, катомизация и настройка всех продуктов из нашей линейки продаж.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
      status: 'busy'
    },
    {
      id: 3,
      fullName: 'Руслан Попов',
      role: 'Директор',
      about:
        'Занимаюсь стратегическим планированием деятельности Компании.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
      status: 'busy'
    },
    {
      id: 4,
      fullName: 'Вера Иванова',
      role: 'Системный аналитик',
      about:
        'Отвечаю за процессы бюджетирования и анализом продукивности работы отделов.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-4.jpg').default,
      status: 'online'
    },
    {
      id: 5,
      fullName: 'Марианна Федина',
      role: 'Бухгалтер',
      about: 'Отвечаю за процессы бюджетирования и анализом продукивности работы отделов',
      avatar: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
      status: 'busy'
    },
    {
      id: 6,
      fullName: 'Сергей Фролов',
      role: 'Маркетолог',
      about:
        'Отвечаю за процессы маркетинга и анализом продукивности работы отделов.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
      status: 'online'
    },
    {
      id: 7,
      fullName: 'Марк Федоров',
      role: 'Аудитор',
      about:
        'Отвечаю за процессы аудита и анализом продукивности работы отделов.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
      status: 'online'
    },
    {
      id: 8,
      fullName: 'Иван Сергеев',
      role: 'Служба безопасности',
      about:
        'Курирую вопросы безопасности и финансовых рисков.',
      avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
      status: 'away'
    },
    {
      id: 9,
      fullName: 'Филипп Недин',
      role: 'Аудитор',
      about:
        'Финансовый и документальный аудит .',
      avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
      status: 'offline'
    },
    {
      id: 10,
      fullName: 'Евгений Коробов',
      role: 'Супервайзер',
      about: 'Курирую Центральный и Восточный регионы по продажам',
      avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
      status: 'away'
    }
  ],
  chats: [
    {
      id: 1,
      userId: 1,
      unseenMsgs: 0,
      chat: [
        {
          message: 'Привет',
          time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Привет, чем могу помочь?',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'Можно мне получить детали последней моей сделки в прошлом месяце?',
          time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Давай проверим, но перед этим мне нужно уточнить пару деталей.',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'Я сообщу кактолько получу ответы.',
          time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'Если это будет долго вы можете прислать мне ответы по email.',
          time: dayBeforePreviousDay,
          senderId: 11
        }
      ]
    },
    {
      id: 2,
      userId: 2,
      unseenMsgs: 1,
      chat: [
        {
          message: "Как мы можем помочь? Мы тут для вас!",
          time: 'Пн Дек 10 2018 07:45:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Привет Андрей, Я ищу лучший вариант для системы мотивации. Ты сможешь мне помочь с этим?',
          time: 'Пн Дек 10 2018 07:45:23 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Это должно быть универсальное решение.',
          time: 'Пн Дек 10 2018 07:45:55 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Полностью!',
          time: 'Пн Дек 10 2018 07:46:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Современная система мотивации для отделов продаж мировых компаний.!',
          time: 'Пн Дек 10 2018 07:46:05 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Выглядит нормально, сейчас посмотрим.',
          time: 'Пн Дек 10 2018 07:46:23 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: "Это будет просто отлично для моего следующего проекта.",
          time: 'Пн Дек 10 2018 07:46:33 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Как я могу купить это?',
          time: 'Пн Дек 10 2018 07:46:43 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Спасибо.',
          time: 'Пн Дек 10 2018 07:46:53 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Я конечно же куплю это. 👍',
          time: previousDay,
          senderId: 1
        }
      ]
    }
  ]
}
/*eslint-enable */
// ------------------------------------------------
// GET: Return Chats Contacts and Contacts
// ------------------------------------------------
mock.onGet('/apps/chat/chats-and-contacts').reply(() => {
  const chatsContacts = data.chats.map(chat => {
    const contact = data.contacts.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })
  const profileUserData = {
    id: data.profileUser.id,
    avatar: data.profileUser.avatar,
    fullName: data.profileUser.fullName,
    status: data.profileUser.status
  }
  return [200, { chatsContacts, contacts: data.contacts, profileUser: profileUserData }]
})

// ------------------------------------------------
// GET: Return User Profile
// ------------------------------------------------
mock.onGet('/apps/chat/users/profile-user').reply(() => [200, data.profileUser])

// ------------------------------------------------
// GET: Return Single Chat
// ------------------------------------------------
mock.onGet('/apps/chat/get-chat').reply(config => {
  // Get event id from URL

  let userId = config.id

  //  Convert Id to number
  userId = Number(userId)

  const chat = data.chats.find(c => c.id === userId)
  if (chat) chat.unseenMsgs = 0
  const contact = data.contacts.find(c => c.id === userId)
  if (contact.chat) contact.chat.unseenMsgs = 0
  return [200, { chat, contact }]
})

// ------------------------------------------------
// POST: Add new chat message
// ------------------------------------------------
mock.onPost('/apps/chat/send-msg').reply(config => {
  // Get event from post data
  const { obj } = JSON.parse(config.data)

  let activeChat = data.chats.find(chat => chat.userId === obj.contact.id)

  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: 11
  }
  // If there's new chat for user create one
  let isNewChat = false
  if (activeChat === undefined) {
    isNewChat = true

    // const lastId = data.chats[length - 1].id

    data.chats.push({
      id: obj.contact.id,
      userId: obj.contact.id,
      unseenMsgs: 0,
      chat: [newMessageData]
    })
    activeChat = data.chats[data.chats.length - 1]
  } else {
    activeChat.chat.push(newMessageData)
  }

  const response = { newMessageData, id: obj.contact.id }
  if (isNewChat) response.chat = activeChat

  return [201, { response }]
})
